import './App.css';
import React, {useEffect, useState} from 'react';
import LoginWindow from './LoginWindow/LoginWindow';
import userDataService from './Services/UserService';
import { FaArrowRightFromBracket, FaAddressCard } from "react-icons/fa6";
import { MdOutlineNewLabel,MdCreateNewFolder,MdFolder,MdComputer,MdAddReaction  } from "react-icons/md";
import { GrUserNew } from "react-icons/gr";
import {Context} from './Context';
import DisplayWindow from './DisplayWindow/DisplayWindow';

const defaultPage = 'Account Settings';
const sleep = ms => new Promise(r => setTimeout(r, ms));

function App() {
  // USER
  const [ apiToken, setApiToken ] = useState(null); // STORES THE TOKEN FOR API INTERACTION
  const [ activeUser, setActiveUser ] = useState({}); // STORES ACTIVE USER
  const [ rememberMe, setRememberMe ] = useState(false); // STORES COOKIES - AUTH TOKEN & ID
  // DISPLAY
  const [screenWidth, setWidth]   = useState(window.innerWidth);
  const [screenHeight, setHeight] = useState(window.innerHeight);
  // NAVIGATION
  const [ navList, setNavList ] = useState([]);  // LIST FOR NAVIGATION BAR
  const [ selectedNav, setSelectedNav ] = useState(defaultPage);  // SELECTED PAGE
  // LOADING & ERRORS
  const [ loginLoading, setLoginLoading ] = useState(false) // STORES WHETHER THE LOGIN IS LOADING
  const [ loginError, setLoginError ] = useState(''); // STORES THE ERRORS ASSOCIATED WITH LOGIN


  // SCREEN DIMENSIONS
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  };
  
  useEffect(() => {   // DISPLAY CHANGE HOOK
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  // LOGIN & LOGOUT
  useEffect(() => {  // CHECKS FOR COOKIES AND CALLS TO LOG IN IF FOUND
    if(localStorage['apiToken'] && apiToken === null){  // LOADS API TOKEN AND USER INFORMATION IF CACHED AS COOKIE - REMEMBER ME
      const accessToken = localStorage.getItem('apiToken')
      setApiToken(accessToken);
      const userID = localStorage.getItem('userID');

      setActiveUserData(userID,accessToken)
    }
  },[])

  const loginUser = async (user = null) => {  // LOGS THE USER IN - STORES COOKIES
    setLoginLoading(true);

    if(user.password.length > 0){
      userDataService.login(user)
      .then(response =>{
        setApiToken(response.data.accessToken);

        if(rememberMe){
          localStorage.setItem('apiToken', response.data.accessToken);
          localStorage.setItem('userID', response.data.id);
        }

        setActiveUser(response.data);
        setUserPrivileges(response.data);        

      }).catch( e=> {
        console.log(e.toString());
        setLoginError(e.toString());
        sleep(3000).then( res => {
          setLoginError('');
      })

      });
    }
      
    setLoginLoading(false);
  };

  const logoutUser = () => {  // LOGS THE USER OUT - CLEARS COOKIES
    setApiToken(null);
    localStorage.removeItem('apiToken');
    localStorage.removeItem('userID');
    goHome();
  };

  const loginProps = {  // PROPS - LOGIN
    loginLoading: loginLoading,
    loginError: loginError,
    login: loginUser,
    rememberMe:rememberMe,
    setRememberMe:setRememberMe,
  };

  // NAVBAR OPTIONS SET HERE BASED ON USER RECORD
  const setUserPrivileges = (userDetails) => {
    let navList = [];

    if(userDetails.vendorPriv){
      navList.push({symbol:MdAddReaction, text:'Vendor Requests'});
      setSelectedNav('Vendor Requests');
    };

    if(userDetails.projectBookingPriv){
      navList.push({symbol:MdCreateNewFolder , text:'New Project Booking'});
      if(navList.length === 1) setSelectedNav('New Project Booking');
    };

    if(userDetails.projectBookingReviewPriv){
      navList.push({symbol:MdFolder , text:'Project Booking Requests'});
      if(navList.length === 1) setSelectedNav('Project Booking Requests');
    };

    if(userDetails.fixedAssetPriv){
      navList.push({symbol:MdOutlineNewLabel , text:'New Fixed Asset'});
      if(navList.length === 1) setSelectedNav('New Fixed Asset');
    };

    if(userDetails.fixedAssetReviewPriv){
      navList.push({symbol:MdComputer , text:'Fixed Asset Requests'});
      if(navList.length === 1) setSelectedNav('Fixed Asset Requests');
    };

    if(userDetails.adminPriv){
      navList.push({symbol:GrUserNew  , text:'Manage Users'});
      if(navList.length === 1) setSelectedNav('Manage Users');
    };

    navList.push({symbol:FaAddressCard , text:'Account Settings'});
    if(navList.length === 1) setSelectedNav('Account Settings');

    navList.push({symbol:FaArrowRightFromBracket, text:'Logout'});
    setNavList(navList);

  };

  const setActiveUserData = (userId,accessToken) =>{  // SETS ACTIVE USER
    const userCredentials = {
      accessToken : accessToken,
      id : userId
    }

    userDataService.getUser(userCredentials)
    .then(response =>{
      setActiveUser(response.data);
      setUserPrivileges(response.data);
    }).catch( e=> {
      console.log(e.toString());
      setLoginError(e.toString());
      logoutUser();
    });
  };

  const changePassword = (currentPassword, newPassword, successfulChange, failedChange) => {
    let passwordObject = {
      id: activeUser.id,
      accessToken: apiToken,
      currentPassword: currentPassword,
      newPassword: newPassword
    }

    userDataService.updatePassword(passwordObject)
    .then(response =>{
      console.log(response.data)
      successfulChange();

    }).catch( e=> {
      console.log(e.toString());
      failedChange(e.toString());

    });
  };


  // Navigation
  const setUserOption = (userSelection) => {
    if(userSelection === 'Logout') logoutUser();
    else{
      setSelectedNav(userSelection);
    }
  };

  const goHome = () => {
    setSelectedNav(defaultPage);
  };


  // Context
  const contextObject = {
    apiToken:apiToken,
    activeUser: activeUser,
    screenWidth:screenWidth,
    screenHeight:screenHeight,
    navList: navList,
    selectedNav: selectedNav,
    setSelectedNav: setSelectedNav,
    setUserOption: setUserOption,
    logoutUser:logoutUser,
    changePassword:changePassword,
    goHome:goHome,
  };



  const dashboardProps = {  //  PROPS - PRIMARY DISPLAY
    activeUser: activeUser,
    screenHeight: screenHeight,
    screenWidth: screenWidth,
  };


// RENDERING APP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  if(apiToken == null){  // IF NO TOKEN IS FOUND PROMPT LOGIN
    return (
      <div className="App">
        <LoginWindow {...loginProps} />
      </div>
    )
  }

  else{   // LOGIN TOKEN FOUND AND RENDER APP
    return(
      <Context.Provider value={contextObject}>

      <div className="App">
          <DisplayWindow {...dashboardProps}/>
      </div>

      </Context.Provider>
    );
  }
}

export default App;
