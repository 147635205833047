import axios from "axios";
import { API_URL } from '../Constants';

class newProjectBookingDataService{
    uploadBudget(accessToken, budget, filename) {
        const formData = new FormData();
        formData.append('file', budget);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}importBudget/`, formData, {headers:{'content-type': 'multipart/form-data','Authorization':accessToken}});
    };

    getBlankTemplate(){
        window.open(`${API_URL}getBudgetTemplate/`)
    };

    getFilteredCostCodeList(accessToken, costCode) {
        return axios.get(`${API_URL}getCostCodeList/${costCode}`, {headers:{'Authorization':accessToken}});
    };

    getFilteredCustomerList(config) {
        return axios.get(`${API_URL}getCustomerList/`, config);
    };

    submitProjectBookingRequest(accessToken, projectBookingRequest) {
        return axios.post(`${API_URL}postProjectBooking/`, projectBookingRequest, {headers:{'Authorization':accessToken}});
    };

    getFilteredProjectManagerList(accessToken, branch) {
        return axios.get(`${API_URL}getProjectManagerList/${branch}`, {headers:{'Authorization':accessToken}});
    };
};

newProjectBookingDataService = new newProjectBookingDataService();
export default newProjectBookingDataService;