import { useEffect, useState,useContext } from 'react';
import './ProjectBooking.css';
import {Context} from '../Context';
import LoadingSpinner from '../Components/LoadingSpinner';
import newProjectBookingDataService from '../Services/ProjectBookingService';
import { MdOutlineEdit } from "react-icons/md";
import { TiCancel } from "react-icons/ti";
import { FaCheck,FaRegCopy,FaStar } from "react-icons/fa";
import { MdExpandMore,MdExpandLess  } from "react-icons/md";

const taxesCode = '01.01.02.00';
const salesTaxCode = '01.01.02.01';
const useTaxCode = '01.01.02.02';
const bondCode = '01.01.01.01';
const perDiemCode = '01.02.01.02';

const branchOptions = [
    {text:'02 - DEC Construction', value:'02'},
    {text:'04 - Electronics', value:'04'},
    {text:'06 - C3 Mattresses', value:'06'},
    {text:'07 - Airteq Locks', value:'07'},
    {text:'09 - Modular Construction', value:'09'},
    {text:'12 - Service North', value:'12'},
    {text:'61 - Service East', value:'61'},
    {text:'62 - Service Southwest', value:'62'},
    {text:'64 - Service Southeast', value:'64'},
    {text:'66 - Service South', value:'66'},
]

//  - - - - - - - - - - - API SUPPORT CALLS - - - - - - - - - - -
const sendProjectBudget = async (apiToken, budgetImport, filename, setFormattedBudget, setformattedBudgetError) => {
    newProjectBookingDataService.uploadBudget(apiToken,budgetImport[0],filename)
    .then(response =>{
        setFormattedBudget(response.data.formattedBudget);

    }).catch( e=> {
        setformattedBudgetError(e.toString());

    });
};

const getBudgetTemplate = async () => {
    newProjectBookingDataService.getBlankTemplate()
};

const getFilteredCostCodeList = async (apiToken, costCode, setCostCodeList) => {
    newProjectBookingDataService.getFilteredCostCodeList(apiToken,costCode)
    .then(response =>{
        setCostCodeList(response.data.responseArray);
    }).catch( e=> {
        console.log(e.toString());
    });
};

const getFilteredCustomers = async (apiToken,customerNumber,customerName,customerStreet,customerCity,customerState,customerZip,setCustomerList,setEmpty,setError) => {
    const customerSearchObject = {
        number: `${customerNumber}`,
        name: `${customerName}`,
        street: `${customerStreet}`,
        city: `${customerCity}`,
        state: `${customerState}`,
        zip: `${customerZip}`,
    };

    const config = {
        headers: {
            'Authorization': apiToken
        },
        params: customerSearchObject
    };

    newProjectBookingDataService.getFilteredCustomerList(config)
    .then(response =>{
        if(response.status > 199 && response.status < 300){
            if(response.status === 204) setEmpty(true)
            else{
                setCustomerList(response.data);
            }
        }
        else{
            setError('Failed to search customers. Please try again and contact IT if this issue continues.');
        }

    }).catch( e=> {
        setError('Failed to search customers. Please try again and contact IT if this issue continues.');
        console.log(e.toString());
    });
};

const sendProjectBookingRequest = async (apiToken,projectBookingObject,setProjectBookingSuccess,setProjectBookingError) => {
    newProjectBookingDataService.submitProjectBookingRequest(apiToken,projectBookingObject)
    .then(response =>{
        setProjectBookingSuccess(response.data);
    }).catch( e=> {
        setProjectBookingError(e.toString());
    });
};

const getFilteredProjectManagers = async (apiToken,branch,setProjectManagerList,setProjectManagerError) => {
    newProjectBookingDataService.getFilteredProjectManagerList(apiToken,branch)
    .then(response =>{
        if(response.data.length > 0){
            let projectManagerOptions = [];
            response.data.forEach(element => {
                const tempPM = {text: `${element.id} - ${element.name} - ${element.email}`, value: element.id}
                projectManagerOptions.push(tempPM);
            });
            setProjectManagerList(projectManagerOptions);
        }
        else{
            setProjectManagerList([{text:'No Project Managers Listed', value:null},]);
        }

    }).catch( e=> {
        setProjectManagerError(e.toString());
    });
};

//  - - - - - - - - - - - HELPER FUNCTIONS - - - - - - - - - - -
const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = mm + '.' + dd + '.' + yyyy;
    return today;
};

const removeArrayItem = (array, index) => { 
    if (index > -1) {
        array.splice(index,1);
    }
    return array;
};

const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const buildCustomerAddress = (location) => {
    let addressString = ''

    if(location.address.street){
        addressString = addressString + location.address.street;
    };

    if(location.address.street2){
        addressString = addressString + ' ' + location.address.street2;
    };

    if(location.address.city){
        addressString = addressString + ' ' + location.address.city;
    };

    if(location.address.state){
        addressString = addressString + ', ' + location.address.state;
    };

    if(location.address.zip){
        addressString = addressString + ' ' + location.address.zip;
    };        

    return addressString;
};

//  - - - - - - - - - - - BUDGET COMPONENTS - - - - - - - - - - -
const ColumnComponent = ({title, value, setTo, editing, width, codeList, setTitle, options}) => {
    const [match, setMatch] = useState(false);

    const cssProps = {
        '--columnWidth': `${width}px`
    };

    useEffect(() => {
        setMatch(false);

        if(codeList){
            codeList.forEach(element => {
                if(element.costCode === value){
                    setMatch(true);
                    setTo(value);
                    setTitle(element.title);
                }
            });
        };
    },[value]);

    return (
        <>
        {   !editing && title !== 'Cost' &&
            <div className='Project-Booking-Budget-Line-Column' style={cssProps}>
                {value}
            </div>
        }
        {   !editing && title === 'Cost' &&
            <div className='Project-Booking-Budget-Line-Column' style={cssProps}>
                {`$${numberWithCommas(value)}`}
            </div>
        }
        {   editing &&
        <div className='Project-Booking-Budget-Line-Column-Edit'>
            {   !options &&
                <input className='Project-Booking-Budget-Line-Column' value={value} onChange={e => setTo(e.target.value)} style={cssProps}/>
            }
            {   options &&
                <select className='Project-Booking-Budget-Line-Column' value={value} style={cssProps} onChange={e => setTo(e.target.value)}>
                    {   
                        options.map((element, key) => (
                            <option key={key} value={element.value}>{element.text}</option>
                        ))
                    }
                </select>
            }
            {   codeList &&
                <div className='Project-Booking-Budget-Line-CC-List'>
                    {   codeList && !match &&
                    codeList.map((element,key) => (
                        <div className='Project-Booking-Budget-Line-CC-List-Item' key={key} onClick={() => {setTo(element.costCode); setTitle(element.title)}}>
                            {`${element.costCode} - ${element.title}`}
                        </div>
                    ))
                    }
                </div>
            }
        </div>
        
        }
        </>
    )
};

const BudgetControlComponent = ({hovering, expanded, setExpanded }) => {
    return(
        <div className='Project-Booking-Budget-Line-Control'>
            {   !expanded && hovering &&
            <div className='Project-Booking-Budget-Line-Button' onClick={() => setExpanded(true)}>
                <MdExpandMore className='Project-Booking-Budget-Line-Button-Icon'/>
            </div>
            }
            {   expanded &&
            <div className='Project-Booking-Budget-Line-Button' onClick={() => setExpanded(false)}>
                <MdExpandLess  className='Project-Booking-Budget-Line-Button-Icon'/>
            </div>
            }
        </div>
    )
};

const EstimateControlComponent = ({editing, hovering, setEditing, deleteLine, submitChanges}) => {
    return(
        <div className='Project-Booking-Budget-Line-Control'>
            {   !editing && hovering &&
            <div className='Project-Booking-Budget-Line-Button' onClick={() => setEditing(true)}>
                <MdOutlineEdit className='Project-Booking-Budget-Line-Button-SM-Icon'/>
            </div>
            }
            {   editing &&
            <>
                <div className='Project-Booking-Budget-Line-Button'>
                    <TiCancel className='Project-Booking-Budget-Line-Button-SM-Icon Icon-Cancel' onClick={() => deleteLine()}/>
                </div>

                <div className='Project-Booking-Budget-Line-Button'>
                    <FaCheck className='Project-Booking-Budget-Line-Button-SM-Icon Icon-Approve' onClick={() => submitChanges()}/>
                </div>
            </>
            }
        </div>
    )
};

const BudgetLine = ({costCode='', title='', index=null, description, group, total, estimateItemArray,updateBudgetLine,deleteEstimateItem}) => {
    const [lineCostCode, setCostCode] = useState(costCode);
    const [lineTitle, setTitle] = useState(title);  
    const [accountGroup, setGroup] = useState(group);
    const [lineTotal, setTotal] = useState(total);
    const [expanded, setExpanded ] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('');

    const [hovering, setHovering] = useState();

    const cssProps = {
    '--backgroundColor':backgroundColor
    }

    useEffect(() => {
        if(expanded) setBackgroundColor('rgb(204, 204, 204)');
        else setBackgroundColor('');
    },[expanded]);

    return(
        <>
        <div className='Project-Booking-Budget-Line' style={cssProps} onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)} onClick={() => setExpanded(!expanded)}>
            <ColumnComponent title={'Cost Code'} value={lineCostCode} setTo={setCostCode} width={100}/>
            <ColumnComponent title={'Code Title'} value={lineTitle} width={350}/>
            <ColumnComponent title={'Account Group'} value={accountGroup} setTo={setGroup} width={120}/>
            <ColumnComponent title={'Cost'} value={`${parseFloat(lineTotal).toFixed(2)}`} setTo={setTotal} width={100}/>
            <BudgetControlComponent hovering={hovering} expanded={expanded} setExpanded={setExpanded}/>
        </div>
        {   expanded &&
            estimateItemArray.map((element,key) => (
                <EstimateLineItem key={key} costCode={costCode} index={key} description={element.description} group={element.group} total={element.total} updateBudgetLine={updateBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
            ))
        }
        </>
    )
};

const EstimateLineItem = ({costCode='', title='', index=null, description, group, total, assignBudgetLine, updateBudgetLine, deleteEstimateItem}) => {
    const contextObject = useContext(Context);

    const [editing, setEditing] = useState(false);
    const [lineCostCode, setCostCode] = useState(costCode);
    const [lineTitle, setTitle] = useState(title);  
    const [lineDescription, setDescription] = useState(description);
    const [accountGroup, setGroup] = useState(group);
    const [lineTotal, setTotal] = useState(total);

    const [costCodeList, setCostCodeList] = useState([]);
    const [hovering, setHovering] = useState();

    const ccsProps = {
        '--backgroundColor' : 'rgb(220, 220, 220)',
    };

    const resetFields = () => {
        setCostCodeList([]);
        setCostCode(costCode);
        setTitle(title);
        setDescription(description);
        setGroup(group);
        setTotal(total);
    };

    useEffect(() => {
        setCostCodeList([]);
    },[lineTitle]);

    useEffect(() => {
        resetFields();
    },[costCode,title,description,group,total]);

    useEffect(() => {
        if(lineCostCode) getFilteredCostCodeList(contextObject.apiToken,lineCostCode,setCostCodeList);
    },[lineCostCode]);

    const submitChanges = () => {
        setEditing(false);
        if(costCode === lineCostCode && group === accountGroup && total === lineTotal){
            // Nothing Changed
        }
        else{
            if(assignBudgetLine) assignBudgetLine(index,lineCostCode,lineDescription,accountGroup,lineTitle,lineTotal);
            else updateBudgetLine(index,costCode,group,total,lineCostCode,lineDescription,accountGroup,lineTitle,lineTotal)
        };
    };

    const deleteLine = () => {
        setEditing(false);
        deleteEstimateItem(costCode,group,index,total);
    };

    return(
        <div className='Project-Booking-Budget-Line' onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)} style={ccsProps}>
            {   !editing &&
                <ColumnComponent title={'Space'} value={''} width={100}/>
            }
            {   editing &&
                <ColumnComponent title={'Cost Code'} value={lineCostCode} setTo={setCostCode} editing={editing} width={90} codeList={costCodeList} setTitle={setTitle}/>
            }
            {   !editing &&
                <ColumnComponent title={'Description'} value={lineDescription} setTo={setDescription} editing={editing} width={350}/>
            }
            {   editing &&
                <ColumnComponent title={'Description'} value={lineDescription} setTo={setDescription} editing={editing} width={342}/>
            }
            {   !editing &&
                <ColumnComponent title={'Account Group'} value={accountGroup} setTo={setGroup} editing={editing} width={120}/>
            }
            {   editing &&
                <ColumnComponent title={'Account Group'} value={accountGroup} setTo={setGroup} editing={editing} width={115} options={[{text:'M',value:'M'},{text:'L',value:'L'},{text:'S',value:'S'},{text:'E',value:'E'},{text:'O',value:'O'}]}/>
            }
            <ColumnComponent title={'Cost'} value={`${lineTotal}`} setTo={setTotal} editing={editing} width={100}/>
            <EstimateControlComponent editing={editing} hovering={hovering} setEditing={setEditing} deleteLine={deleteLine} submitChanges={submitChanges}/>
        </div>
    );
};

const ProjectBudgetForm = ({formattedBudget, assignBudgetLine, updateBudgetLine, setTaxesFound, setPerDiemFound, setBondFound, deleteEstimateItem}) => {
    return(
        <div className='Project-Booking-Budget-Form'> 
            {
            Object.keys(formattedBudget).map((identifier,key) => {
                if(identifier != 'unassigned') {
                    if(formattedBudget[identifier].costCode === taxesCode || formattedBudget[identifier].costCode === useTaxCode || formattedBudget[identifier].costCode === salesTaxCode) setTaxesFound(true);
                    if(formattedBudget[identifier].costCode === perDiemCode) setPerDiemFound(true);
                    if(formattedBudget[identifier].costCode === bondCode) setBondFound(true);
                    
                    return(
                        <BudgetLine key={key} title={formattedBudget[identifier].title} costCode={formattedBudget[identifier].costCode} group={formattedBudget[identifier].group} total={formattedBudget[identifier].total} estimateItemArray={formattedBudget[identifier].estimateItemArray} updateBudgetLine={updateBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
                    )
                };
            })
            }

            {   formattedBudget.unassigned.length > 0 &&
                <div className='Project-Booking-Budget-Section-Title'>Needs Assigned</div>
            }
            { formattedBudget.unassigned.length > 0 &&
            formattedBudget.unassigned.map((element,key) => (
                <EstimateLineItem key={key} index={key} description={element.description} group={element.group} total={element.total} assignBudgetLine={assignBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
            ))
            }

            {

            }
        </div>
    )
};

const TotalsBar = ({materialTotal,laborTotal,subcontractorTotal,equipmentTotal,otherTotal,totalBudget}) => {
    return(
        <div className='Project-Booking-Budget-Totals'>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Materials $${materialTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Labor $${laborTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Subcontractors $${subcontractorTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Equipment $${equipmentTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Other $${otherTotal}`}
            </div>
            <div className='Project-Booking-Budget-Totals-Item'>
                {`Total Budget $${totalBudget}`}
            </div>
        </div>
    )
};


//  - - - - - - - - - - - FIELD COMPONENTS - - - - - - - - - - -
const StandardInputField = ({title, value, setTo, locked, width=300, searchCustomer}) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(searchCustomer) searchCustomer();
        }
    };

    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   !locked &&
                <input className='Project-Booking-Input-Field-Input' style={{width:`${width}px`}} value={value} onChange={e => setTo(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
            }
            {   locked &&
                <input className='Project-Booking-Input-Field-Input' disabled style={{width:`${width}px`}} value={value} onChange={e => setTo(e.target.value)}/>
            }
        </div>
    )
};

const PercentageInputField = ({title, value, setTo, locked=false}) => {
    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            <input className='Project-Booking-Input-Field-Input' disabled={locked} style={{width:'50px'}} value={value} onChange={e => setTo(e.target.value)}/>
            <div>%</div>
        </div>
    )
};

const DollarInputField = ({title, value, setTo, locked}) => {
    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            <div>$</div>
            <input className='Project-Booking-Input-Field-Input' style={{width:'100px'}} type="number" value={value} onChange={e => setTo(e.target.value)}/>
        </div>
    )
};


const AddressInputField = ({title, street, setStreet, city, setCity, state, setState, zip, setZip,locked,searchCustomer}) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(searchCustomer) searchCustomer();
        }
    };
    
    return(
        <div className='Project-Booking-Input-Field'>
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            {   !locked &&
            <>
                <input className='Project-Booking-Input-Field-Input' style={{width:'250px'}} placeholder={'Street'} value={street} onChange={e => setStreet(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <input className='Project-Booking-Input-Field-Input' style={{width:'100px'}} placeholder={'City'} value={city} onChange={e => setCity(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <input className='Project-Booking-Input-Field-Input' maxLength={2} style={{width:'40px'}} placeholder={'State'} value={state} onChange={e => setState(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <input className='Project-Booking-Input-Field-Input' maxLength={5} style={{width:'55px'}} placeholder={'Zip Code'} value={zip} onChange={e => setZip(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
            </>
            }
            {   locked &&
            <>
                <input className='Project-Booking-Input-Field-Input' style={{width:'250px'}} value={street} onChange={e => setStreet(e.target.value)} disabled/>
                <input className='Project-Booking-Input-Field-Input' style={{width:'100px'}} value={city} onChange={e => setCity(e.target.value)} disabled/>
                <input className='Project-Booking-Input-Field-Input' maxLength={2} style={{width:'40px'}} value={state} onChange={e => setState(e.target.value)} disabled/>
                <input className='Project-Booking-Input-Field-Input' maxLength={5} style={{width:'55px'}}  value={zip} onChange={e => setZip(e.target.value)} disabled/>
            </>
            }
        </div>
    )
};

const SelectInputField = ({title, value, setTo, options}) => {
    return(
        <div className='Project-Booking-Input-Field'>          
            <div className='Project-Booking-Input-Field-Label'>
                {title}:
            </div>
            <select onChange={e => setTo(e.target.value)} value={value}>
                <option value="" disabled hidden>Select an option</option>
                {   
                options.map((element, key) => (
                    <option key={key} value={element.value}>{element.text}</option>
                ))
                }
            </select>
        </div>
    )
};

//  - - - - - - - - - - - CUSTOMER - - - - - - - - - - -
const CustomerLocationHeader = () => {
    const LocationHeaderColumn = ({width, text}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Header-Column' style={{width:`${width}px`}}>
                {text}
            </div>
        )
    };
    return (
        <div className='Project-Booking-Customer-Selection-Header'>
            <LocationHeaderColumn width={180} text={'Location Title'}/>
            <LocationHeaderColumn width={420} text={'Location Address'}/>
        </div>
    )
};

const CustomerSelectionHeader = ({sortOnName,sortOnId,sortOnClass}) => {
    const CustomerHeaderColumn = ({width, text, clickFunction}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Header-Column' style={{width:`${width}px`}} onClick={() => clickFunction()}>
                {text}
            </div>
        )
    };

    return (
        <div className='Project-Booking-Customer-Selection-Header'>
            <CustomerHeaderColumn width={120} text={'Customer ID'} clickFunction={() => sortOnId}/>
            <CustomerHeaderColumn width={300} text={'Customer Name'} clickFunction={() => sortOnName}/>
            <CustomerHeaderColumn width={150} text={'Customer Class'} clickFunction={() => sortOnClass}/>
            <CustomerHeaderColumn width={180} text={'Location Title'}/>
            <CustomerHeaderColumn width={420} text={'Location Address'}/>
        </div>
    )
};

const CustomerLocationRow = ({location,selectLocation}) => {
    const LocationSelectColumn = ({width, text}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Column' style={{width:`${width}px`}}>
                {text}
            </div>
        )
    };

    return(
        <div className='Project-Booking-Customer-Selection-Row' onClick={() => selectLocation(location)}>
            <LocationSelectColumn width={180} text={location.locationName}/>
            <LocationSelectColumn width={420} text={buildCustomerAddress(location)}/>
        </div>
    )
};

const CustomerSelectionRow = ({customer,selectCustomer}) => {
    const CustomerSelectColumn = ({width, text}) => {
        return(
            <div className='Project-Booking-Customer-Selection-Column' style={{width:`${width}px`}}>
                {text}
            </div>
        )
    };

    return(
        <div className='Project-Booking-Customer-Selection-Row' onClick={() => selectCustomer(customer)}>
            <CustomerSelectColumn width={120} text={customer.customerId}/>
            <CustomerSelectColumn width={300} text={customer.name}/>
            <CustomerSelectColumn width={150} text={customer.class}/>
            <CustomerSelectColumn width={180} text={customer.locations[0].locationName}/>
            <CustomerSelectColumn width={420} text={buildCustomerAddress(customer.locations[0])}/>
        </div>
    )
};

const CustomerSelectionWindow = ({customerList,selectCustomer,customerSelected,sortOnName,sortOnId,sortOnClass}) => {
    return(
        <div className='Project-Booking-Customer-Selection'>
            <CustomerSelectionHeader sortOnName={sortOnName} sortOnId={sortOnId} sortOnClass={sortOnClass}/>

            {  customerList &&
                customerList.map((customer,key) => (
                    <CustomerSelectionRow key={key} customer={customer} selectCustomer={selectCustomer} customerSelected={customerSelected}/>
                ))
            }
        </div>
    )
};

const SelectedCustomerWindow = ({customer, locationSelected, setLocationSelected}) => {

    const selectLocation = (location) => {
        setLocationSelected(location);
    };

    const CustomerLine = ({title,value}) => {
        return (
            <div className='Project-Booking-Selected-Customer-Line'>
                <div className='Project-Booking-Selected-Customer-Line-Title'>
                    {title}
                </div>
                <div className='Project-Booking-Selected-Customer-Line-Value'>
                    {value}
                </div>
            </div>
        )
    };

    return(
        <div className='Project-Booking-Selected-Customer-Window'>
            <CustomerLine title={'ID:'} value={customer.customerId}/>
            <CustomerLine title={'Name:'} value={customer.name}/>
            <CustomerLine title={'Class:'} value={customer.class}/>
            
            {   !locationSelected &&
                <div className='Project-Booking-Selected-Customer-Locations'>
                    <div className='Project-Booking-Selected-Customer-Locations-Title'>
                        Please select a customer location below.
                    </div>
                    <div className='Project-Booking-Selected-Customer-Location-Table'>
                        < CustomerLocationHeader />
                        {
                            customer.locations.map((element,key) => (
                                <CustomerLocationRow location={element} selectLocation={selectLocation}/>
                            ))
                        }
                    </div>

                </div>
            }
            {   locationSelected &&
                <CustomerLine title={'Location:'} value={buildCustomerAddress(locationSelected)}/>
            }
        </div>
    )
};

const CustomerDetailsForm = ({entityName,setEntityName,customerNumber,setCutomerNumber,customerName,setCustomerName,
    customerStreet,setCustomerStreet,customerCity,setCustomerCity,customerState,setCustomerState,customerZip,setCustomerZip,
    customerList,customerSearch,clearCustomerList,customerSelected,setCustomerSelected,locationSelected,setLocationSelected,
    customerPoNumber, setCustomerPoNumber,
    sortOnName,sortOnId,sortOnClass}) => {
    const [entitySelection, setEntitySelection] = useState(true);
    const [enitityShown, setEntityShown] = useState(false);

    const [searchingCustomer, setSearchingCustomer] = useState(false);
    const [emptyResults, setEmptyResults] = useState(false);
    const [error,setError] = useState('');

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if(emptyResults){
            setEmptyResults(false);
        };
        if(customerList){
            clearCustomerList();
        }
    },[customerNumber,customerName,customerStreet,customerCity,customerState,customerZip]);

    useEffect(() => {
        if(customerList && customerList.length > 0){
            setSearchingCustomer(false);
        }
    },[customerList]);

    useEffect(() => {
        setSearchingCustomer(false);
    },[emptyResults]);
 
    useEffect(() => {
        if(entitySelection === 'true' || entitySelection === true){
            setEntityShown(false);
            setEntityName(customerName);
        }
        else{
            setEntityShown(true);
            setEntityName('');
        }
    },[entitySelection,customerName]);

    const selectCustomer = (customer) => {
        console.log(customer)
        setCustomerSelected(customer);
        if(customer.locations.length < 2){
            console.log('Only 1 location found. Selecting')
            setLocationSelected(customer.locations[0]);
        }
        clearCustomerList();
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText('https://www.cornerstoneinc.io/newCustomer');
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        },1000);
    };

    const clearCustomer = () => {
        setError('');
        setSearchingCustomer(false);
        setCutomerNumber('');
        setCustomerName('')
        setCustomerStreet('');
        setCustomerCity('');
        setCustomerState('');
        setCustomerZip('');
        setCustomerSelected(false);
        clearCustomerList();
        setEmptyResults(false);
    };

    const searchCustomer = () => {
        setSearchingCustomer(true);
        customerSearch(setEmptyResults,setError);
    };

    const checkValidInput = () => {
        if(customerNumber.length > 2 || customerName.length > 2 || customerStreet.length > 2 || customerCity.length > 2 || customerState.length >= 2 || customerZip.length > 2) return true;
        else return false;
    };

    return(
        <div className='Project-Booking-Input-Form'>
            {   (!searchingCustomer && !customerSelected && !customerList) && (customerNumber.length <= 2 && customerName.length <= 2) &&
                <div className='Project-Booking-Customer-Search-Message'>
                    Start by searching for a customer using either their number, name, or address.
                </div>
            }

            {   !customerSelected &&
            <>
            <StandardInputField title={'Customer Number'} value={customerNumber} setTo={setCutomerNumber} searchCustomer={searchCustomer}/>
            <StandardInputField title={'Customer Name'} value={customerName} setTo={setCustomerName} searchCustomer={searchCustomer}/>
            <AddressInputField title={'Customer Address'} street={customerStreet} setStreet={setCustomerStreet} city={customerCity} setCity={setCustomerCity} state={customerState} setState={setCustomerState} zip={customerZip} setZip={setCustomerZip} locked={false} searchCustomer={searchCustomer}/>
            </>
            }

            {   customerSelected &&
            <>
                <SelectedCustomerWindow customer={customerSelected} locationSelected={locationSelected} setLocationSelected={setLocationSelected}/>
                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Clear Customer</button>
            </>
            }

            {   !emptyResults && (!searchingCustomer && !customerSelected && !customerList) && !checkValidInput() &&
                <button className='Project-Booking-Customer-Search-Button'>Search for Customer</button>
            }

            {   !emptyResults && (!searchingCustomer && !customerSelected && !customerList) && checkValidInput() &&
                <button className='Project-Booking-Customer-Search-Button Search-Button-Active' onClick={() => searchCustomer()}>Search for Customer</button>
            }

            {   searchingCustomer && !error &&
                <LoadingSpinner size={60}/>
            }
            {   customerList && customerList.length > 0 && !searchingCustomer &&
            <>
                <CustomerSelectionWindow customerList={customerList} selectCustomer={selectCustomer} customerSelected={customerSelected} sortOnName={sortOnName} sortOnId={sortOnId} sortOnClass={sortOnClass}/>
                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Clear Customer</button>
            </>
            }

            {   emptyResults &&
            <div className='Project-Booking-Customer-Empty'>
                <div className='Project-Booking-Customer-Empty-Message'>
                    No Customer Found.
                </div>
                <div  className='Project-Booking-Customer-Empty-Message'>
                    To add a new customer to the system, please send them the link below.
                </div>
                <div className='Project-Booking-Customer-Link-Container'>
                    <div className='Project-Booking-Customer-Link-Text'>
                        https://www.cornerstoneinc.io/newCustomer
                    </div>
                    <div className='Project-Booking-Customer-Link-Copy' onClick={() => copyToClipboard()}>
                        {   !copied &&
                            <FaRegCopy  className='Project-Booking-Customer-Link-Copy-Image'/>
                        }
                        {   copied &&
                            <FaCheck  className='Project-Booking-Customer-Link-Copy-Image'/>
                        }
                    </div>

                    {   copied &&
                    <div  className='Project-Booking-Customer-Empty-Message'>
                        Copied Link
                    </div>
                    }
                </div>


                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Try Again</button>
            </div>
            }

            {   customerSelected &&
            <>
            <SelectInputField title={'Entity Name on Contract or PO same as Customer'} value={entitySelection} setTo={setEntitySelection} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            { enitityShown &&
            <StandardInputField title={'Entity Name on Contract or PO'} value={entityName} setTo={setEntityName}/>
            }
            <StandardInputField title={'Customer PO Number'} value={customerPoNumber} setTo={setCustomerPoNumber}/>
            </>
            }

            {   error &&
            <>
                <div className='Project-Booking-Customer-Error'>
                    {error}
                </div>
                <button className='Project-Booking-Customer-Clear-Button' onClick={() => clearCustomer()}>Try Again</button>
            </>
            }

        </div>
    )
};

//  - - - - - - - - - - - BRANCH & MANAGER - - - - - - - - - - - 
const BranchManagementForm = ({projectManager,setProjectManager,projectManagerList,branch,setBranch}) => {
    return(
        <>
            <SelectInputField title={'Branch'} value={branch} setTo={setBranch} options={branchOptions}/>
            {   projectManagerList.length < 1 && branch &&
                <LoadingSpinner size={60}/>
            }
            {   projectManagerList.length > 0 && projectManagerList[0].value &&
                <SelectInputField title={'Project Manager'} value={projectManager} setTo={setProjectManager} options={projectManagerList}/>
            }
            {   projectManagerList.length > 0 && !projectManagerList[0].value &&
            <>
                <div>
                    No Project Managers Listed at This Branch.
                </div>
                <div>
                    Please select a different branch or reach out to IT to add PMs to the system for this branch.
                </div>
            </>
            }

        </>
    )
};

const ProjectTypeForm = ({projectType, setProjectType}) => {
    return(
        <SelectInputField title={'Job Type'} value={projectType} setTo={setProjectType} options={[{text:'Project', value:'Project'},{text:'Service Contract',value:'Service'}]}/>
    )
};

//  - - - - - - - - - - - PROJECT - - - - - - - - - - - 
const ProjectDetailsForm = ({projectName,setProjectName,projectStreet,setProjectStreet,projectCity,setProjectCity,projectState,setProjectState,projectZip,setProjectZip,
    ownerDirectPurchase,setOwnerDirectPurchase,prevailingWage,setPrevailingWage,bondRequired,setBondRequired,businessLicenseRequired,setBusinessLicenseRequired,premitsRequired,setPermitsRequired,
    ccipInsurance,setCcipInsurance,taxStatus,setTaxStatus}) => {

    return(
        <div className='Project-Booking-Input-Form'>
            <StandardInputField title={'Project Name'} value={projectName} setTo={setProjectName}/>
            <AddressInputField title={'Project Address'} street={projectStreet} setStreet={setProjectStreet} city={projectCity} setCity={setProjectCity} state={projectState} setState={setProjectState} zip={projectZip} setZip={setProjectZip}/>
            <SelectInputField title={'Tax Status'} value={taxStatus} setTo={setTaxStatus} options={[{text:'Customer Taxed', value:'Customer'},{text:'Contractor Taxed',value:'Contractor'},{text:'Exempt',value:'Exempt'}]}/>
            <SelectInputField title={'Owner Direct Purchase'} value={ownerDirectPurchase} setTo={setOwnerDirectPurchase} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Certified Payroll / Prevailing Wage'} value={prevailingWage} setTo={setPrevailingWage} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Bond Required'} value={bondRequired} setTo={setBondRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Business License Required'} value={businessLicenseRequired} setTo={setBusinessLicenseRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Permits Required'} value={premitsRequired} setTo={setPermitsRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'CCIP or OCIP Insurance'} value={ccipInsurance} setTo={setCcipInsurance} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
        </div>
    )
};

//  - - - - - - - - - - - SERVICE - - - - - - - - - - - 
const ServiceDetailsForm = ({projectName,setProjectName,
    projectStreet,setProjectStreet,projectCity,setProjectCity,projectState,setProjectState,projectZip,setProjectZip,
    ownerDirectPurchase,setOwnerDirectPurchase,prevailingWage,setPrevailingWage,
    bondRequired,setBondRequired,businessLicenseRequired,setBusinessLicenseRequired,premitsRequired,setPermitsRequired,
    costAccountGroup,setCostAccountGroup,ccipInsurance,setCcipInsurance,customerPoNumber,setCustomerPoNumber,
    billingType,setBillingType,defaultRetainage,setDefaultRetainage,taxStatus,setTaxStatus}) => {

    return(
        <div className='Project-Booking-Input-Form'>
            <StandardInputField title={'Project Name'} value={projectName} setTo={setProjectName}/>
            <AddressInputField title={'Project Address'} street={projectStreet} setStreet={setProjectStreet} city={projectCity} setCity={setProjectCity} state={projectState} setState={setProjectState} zip={projectZip} setZip={setProjectZip}/>
            <StandardInputField title={'Customer PO Number'} value={customerPoNumber} setTo={setCustomerPoNumber}/>
            <SelectInputField title={'Billing Type'} value={billingType} setTo={setBillingType} options={[{text:'Quick Bill', value:'Quick'},{text:'Progressive Billing',value:'Progressive'}]}/>
            <PercentageInputField title={'Default Retainage'} value={defaultRetainage} setTo={setDefaultRetainage}/>
            <SelectInputField title={'Tax Status'} value={taxStatus} setTo={setTaxStatus} options={[{text:'Customer Taxed', value:'Customer'},{text:'Contractor Taxed',value:'Contractor'},{text:'Exempt',value:'Exempt'}]}/>
            <SelectInputField title={'Owner Direct Purchase'} value={ownerDirectPurchase} setTo={setOwnerDirectPurchase} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Certified Payroll / Prevailing Wage'} value={prevailingWage} setTo={setPrevailingWage} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Bond Required'} value={bondRequired} setTo={setBondRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Business License Required'} value={businessLicenseRequired} setTo={setBusinessLicenseRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'Permits Required'} value={premitsRequired} setTo={setPermitsRequired} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
            <SelectInputField title={'CCIP or OCIP Insurance'} value={ccipInsurance} setTo={setCcipInsurance} options={[{text:'Yes', value:true},{text:'No',value:false}]}/>
        </div>
    )
};

const ProjectContractForm = ({defaultRetainage,setDefaultRetainage,totalContractAmount,setTotalContractAmount,totalCostAmount,setTotalCostAmount,margin,setMargin}) => {
    return(
        <div className='Project-Booking-Input-Form'>
            <DollarInputField title={'Total Contract Amount'} value={totalContractAmount} setTo={setTotalContractAmount}/>
            <DollarInputField title={'Total Estimated Cost'} value={totalCostAmount} setTo={setTotalCostAmount}/>
            <PercentageInputField title={'margin'} value={margin} setTo={setMargin} locked={true}/>
            <PercentageInputField title={'Default Retainage'} value={defaultRetainage} setTo={setDefaultRetainage}/>
        </div>
    )
};

const FilesForm = ({prevailingWage,setPrevailingWageFile,bondRequired,setBondFile,taxStatus,setTaxExemptFile,ccipInsurance,setCertificateFile}) => {

    return(
        <div>
            {   prevailingWage === 'true' &&
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload the Certified Payroll / Prevailing Wage Rate Sheet.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setPrevailingWageFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   bondRequired === 'true' &&
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload the bonding requirements
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setBondFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   taxStatus === 'Exempt' &&
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload the tax exemption certificate
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setTaxExemptFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
            {   ccipInsurance === 'true' &&
            <div className='Project-Booking-File-Insert'>
                <div className='Project-Booking-File-Insert-Description'>
                    Upload CCIP or OCIP Insurance Details.
                </div>
                <input type='file' accept=".pdf" onChange={(e) => setCertificateFile(e.target.files)}className='Project-Booking-File-Insert-File'/>
            </div>
            }
        </div>
    )
};

const BudgetSkipForm = ({budgetSkipReason,setBudgetSkipReason,skipBudget}) => {
    return(
        <div className='Project-Booking-Skip-Budget'>
            <textarea className='Project-Booking-Skip-Budget-Input' value={budgetSkipReason} onChange={e => setBudgetSkipReason(e.target.value)} />
            <button className='Project-Booking-Skip-Budget-Cancel' onClick={ () => skipBudget(false) }>Cancel Budget Skip</button>
        </div>
    )
};

//  - - - - - - - - - - - PRIMARY COMPONENT - - - - - - - - - - - 
const ProjectBooking = () => {
    const contextObject = useContext(Context);

    // CUSTOMER - - - - - - - 
    const [customerSelected, setCustomerSelected] = useState(null);
    const [locationSelected, setLocationSelected] = useState(null);
    const [customerNumber, setCutomerNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerList, setCustomerList] = useState();
    const [customerStreet, setCustomerStreet] = useState('');
    const [customerCity, setCustomerCity] = useState('');
    const [customerState, setCustomerState] = useState('');
    const [customerZip, setCustomerZip] = useState('');
    const [entityName, setEntityName] = useState('');
    const [customerPoNumber, setCustomerPoNumber] = useState('');

    const sortOnName = () => {

    };

    const sortOnId = () => {

    };

    const sortOnClass = () => {

    };

    const clearCustomerList = () => {
        setCustomerList(null);
    };

    const customerSearch = (setEmpty,setError) => {
        setLocationSelected(null);
        getFilteredCustomers(contextObject.apiToken,customerNumber,customerName,customerStreet,customerCity,customerState,customerZip,setCustomerList,setEmpty,setError)
    };

    const customerDetailProps = {
        entityName:entityName,
        setEntityName:setEntityName,
        customerSelected:customerSelected,
        setCustomerSelected:setCustomerSelected,
        locationSelected:locationSelected,
        setLocationSelected:setLocationSelected,
        customerNumber:customerNumber,
        setCutomerNumber:setCutomerNumber,
        customerName:customerName,
        setCustomerName:setCustomerName,
        customerStreet:customerStreet,
        setCustomerStreet:setCustomerStreet,
        customerCity:customerCity,
        setCustomerCity:setCustomerCity,
        customerState:customerState,
        setCustomerState:setCustomerState,
        customerZip:customerZip,
        setCustomerZip:setCustomerZip,
        customerList:customerList,
        customerPoNumber:customerPoNumber, 
        setCustomerPoNumber:setCustomerPoNumber,
        customerSearch:customerSearch,
        clearCustomerList:clearCustomerList,
        sortOnName:sortOnName,
        sortOnId:sortOnId,
        sortOnClass:sortOnClass,
    };


    // BRANCH & MANAGER - - - - - - - 
    const [branch,setBranch] = useState('');
    const [projectManager,setProjectManager] = useState('');
    const [projectManagerList,setProjectManagerList] = useState([]);
    const [projectManagerError,setProjectManagerError] = useState('');
    const [branchAndManagerComplete, setBranchAndManagerComplete] = useState(false);

    useEffect(() => {
        if(branch && projectManager) setBranchAndManagerComplete(true);
        else setBranchAndManagerComplete(false);
    },[branch,projectManager]);

    useEffect(() => {
        if(branch) getFilteredProjectManagers(contextObject.apiToken,branch,setProjectManagerList,setProjectManagerError);
    },[branch]);

    const branchManagementProps = {
        projectManager:projectManager,
        setProjectManager:setProjectManager,
        projectManagerList:projectManagerList,
        branch:branch,
        setBranch:setBranch,
    };


    // PROJECT SELECTION - - - - - - - 
    const [projectType, setProjectType] = useState('Project');


    // PROJECT DETAILS  - - - - - - - 
    const [projectName, setProjectName] = useState('');
    const [projectStreet,setProjectStreet] = useState('');
    const [projectCity,setProjectCity] = useState('');
    const [projectState,setProjectState] = useState('');
    const [projectZip,setProjectZip] = useState('');
    const [ownerDirectPurchase, setOwnerDirectPurchase] = useState(false);
    const [prevailingWage, setPrevailingWage] = useState(false);
    const [bondRequired, setBondRequired] = useState(false);
    const [businessLicenseRequired, setBusinessLicenseRequired] = useState(false);
    const [premitsRequired, setPermitsRequired] = useState(false);
    const [costAccountGroup, setCostAccountGroup] = useState('');
    const [ccipInsurance, setCcipInsurance] = useState(false);
    const [customerTaxExempt, setCustomerTaxExempt] = useState(false);
    const [contractorTaxExempt, setContractorTaxExempt] = useState(false);
    const [billingType, setBillingType] = useState('Progressive');
    const [taxStatus, setTaxStatus] = useState('Customer');
    const [projectNameError, setProjectNameError] = useState('');
    const [projectAddressError, setProjectAddressError] = useState('');
    const [detailsComplete, setDetailsComplete] = useState(false);

    const projectDetailProps = {
        projectName: projectName,
        setProjectName:setProjectName,
        projectStreet:projectStreet,
        setProjectStreet:setProjectStreet,
        projectCity:projectCity,
        setProjectCity:setProjectCity,
        projectState:projectState,
        setProjectState:setProjectState,
        projectZip:projectZip,
        setProjectZip:setProjectZip,
        ownerDirectPurchase:ownerDirectPurchase,
        setOwnerDirectPurchase:setOwnerDirectPurchase,
        prevailingWage:prevailingWage,
        setPrevailingWage:setPrevailingWage,
        bondRequired:bondRequired,
        setBondRequired:setBondRequired,
        businessLicenseRequired:businessLicenseRequired,
        setBusinessLicenseRequired:setBusinessLicenseRequired,
        premitsRequired:premitsRequired,
        setPermitsRequired:setPermitsRequired,
        costAccountGroup:costAccountGroup,
        setCostAccountGroup:setCostAccountGroup,
        ccipInsurance:ccipInsurance,
        setCcipInsurance:setCcipInsurance,
        taxStatus:taxStatus,
        setTaxStatus:setTaxStatus,
    };

    // Checks an address for completeness
    const checkProjectAddress = (street,city,state,zip) => {
        let complete = true;

        if(street.length < 5) complete = false;
        if(city.length < 3) complete = false;
        if(state.length < 2) complete = false;
        if(zip.length < 5) complete = false;

        return complete;
    };

    const checkProjectDetails = () => {
        let complete = true;

        if(projectName.length < 5) {
            complete = false;
            setProjectNameError('Please enter a project name.');
        }
        else setProjectNameError('');

        if(!checkProjectAddress(projectStreet,projectCity,projectState,projectZip)){
            complete = false;
            setProjectAddressError('Please enter a valid project address.');
        }
        else setProjectAddressError('');

        return complete;
    };

    useEffect(() => {
        setDetailsComplete(checkProjectDetails());
    },[projectName,projectStreet,projectCity,projectState,projectZip]);


    // SERVICE DETAILS - - - - - - - 
    const serviceDetailProps = {
        projectName: projectName,
        setProjectName:setProjectName,
        projectStreet:projectStreet,
        setProjectStreet:setProjectStreet,
        projectCity:projectCity,
        setProjectCity:setProjectCity,
        projectState:projectState,
        setProjectState:setProjectState,
        projectZip:projectZip,
        setProjectZip:setProjectZip,
        ownerDirectPurchase:ownerDirectPurchase,
        setOwnerDirectPurchase:setOwnerDirectPurchase,
        prevailingWage:prevailingWage,
        setPrevailingWage:setPrevailingWage,
        bondRequired:bondRequired,
        setBondRequired:setBondRequired,
        businessLicenseRequired:businessLicenseRequired,
        setBusinessLicenseRequired:setBusinessLicenseRequired,
        premitsRequired:premitsRequired,
        setPermitsRequired:setPermitsRequired,
        costAccountGroup:costAccountGroup,
        setCostAccountGroup:setCostAccountGroup,
        ccipInsurance:ccipInsurance,
        setCcipInsurance:setCcipInsurance,
        taxStatus:taxStatus,
        setTaxStatus:setTaxStatus,
        customerPoNumber:customerPoNumber,
        setCustomerPoNumber:setCustomerPoNumber,
        billingType:billingType,
        setBillingType:setBillingType,
    };
    

    // CONTRACT - - - - - - - 
    const [totalContractAmount, setTotalContractAmount] = useState(0);
    const [totalCostAmount, setTotalCostAmount] = useState(0);
    const [defaultRetainage, setDefaultRetainage] = useState();
    const [margin, setMargin] = useState(0);
    const [contract, setContract] = useState();
    const [contractComplete, setContractComplete] = useState(false);
    const [contractValueError, setContractValueError] = useState('');
    const [retainageError, setRetainageError] = useState('');
    const [estiamtedCostError, setEstimatedCostError] = useState('');

    const contractProps = {
        defaultRetainage:defaultRetainage,
        setDefaultRetainage:setDefaultRetainage,
        totalContractAmount:totalContractAmount,
        setTotalContractAmount:setTotalContractAmount,
        totalCostAmount:totalCostAmount,
        setTotalCostAmount:setTotalCostAmount,
        margin:margin,
        setMargin:setMargin,
    };

    useEffect(() => {
        if(totalContractAmount > 0 && totalCostAmount > 0){
            let result = ((1-(totalCostAmount/totalContractAmount))*100);
            setMargin(result.toFixed(2));
        }
        else setMargin(0);

        let complete = true;

        if(totalContractAmount < 1){
            complete = false;
            setContractValueError('Enter a total contract amount.');
        }
        else setContractValueError('');

        if(totalCostAmount < 1){
            complete = false;
            setEstimatedCostError('Enter an estimated cost.');
        }
        else setEstimatedCostError('');

        if(!defaultRetainage){
            complete = false;
            setRetainageError('Enter a default retainage.');
        }
        else setRetainageError('');

        setContractComplete(complete);

    },[totalContractAmount,totalCostAmount,defaultRetainage]);


    // Links - - - - - - - - - - - 
    const [salesLink, setSalesLink] = useState();
    const [salesLinkError, setSalesLinkError] = useState('Please copy and paste the link to the sales folder');
    const [validSalesLink, setValidSalesLink] = useState(false);
    
    const validateSalesLink = () => {
        const validStart = 'https://cornerstonedetentionproducts';

        if(salesLink.startsWith(validStart)) return true;
        else return false
    };

    useEffect(() => {
        if(!salesLink){
            setSalesLinkError('Please copy and paste the link to the sales folder');
            setValidSalesLink(false);
        }
        else{
            if(!validateSalesLink()){
                setSalesLinkError('Please copy a valid Box Link to Continue. To log in, navigate to www.Box.com');
                setValidSalesLink(false);
            }
            else{
                setSalesLinkError('');
                setValidSalesLink(true);
            }
        };
    },[salesLink]);


    // FILES - - - - - - - 
    const [filesRequired, setFilesRequired] = useState(false);
    const [filesComplete, setFilesComplete] = useState(false);
    const [prevailingWageFile, setPrevailingWageFile] = useState();
    const [bondFile, setBondFile] = useState();
    const [taxExemptFile, setTaxExemptFile] = useState();
    const [certificateFile, setCertificateFile] = useState();
    const [prevailingWageFileError, setPrevailingWageFileError] = useState('');
    const [bondFileError, setBondFileError] = useState('');
    const [certificateFileError, setCertificateFileError] = useState('');

    const fileProps = {
        prevailingWage: prevailingWage,
        setPrevailingWageFile:setPrevailingWageFile,
        bondRequired:bondRequired,
        setBondFile:setBondFile,
        taxStatus:taxStatus,
        setTaxExemptFile:setTaxExemptFile,
        ccipInsurance:ccipInsurance,
        setCertificateFile:setCertificateFile,
    };
    
    const checkFilesRequired = () => {
        let complete = true;
        let filesShown = false;

        // PREVAILING WAGE
        if(prevailingWage === 'true'){
            filesShown = true;

            if(!prevailingWageFile){
                setPrevailingWageFileError('Upload a Prevailing Wage Rate Sheet.');
                complete = false;
            }
            else setPrevailingWageFileError('');
        } else setPrevailingWageFileError('');

        // BOND
        if(bondRequired === 'true'){
            filesShown = true;
            if(!bondFile){
                setBondFileError('Upload Bonding Requirements.');
                complete = false;
            }
            else setBondFileError('');
        }else setBondFileError('');

        if(taxStatus === 'Exempt'){
            filesShown = true;
        }

        if(ccipInsurance === 'true'){
            filesShown = true;
            if(!certificateFile){
                setCertificateFileError('Upload CCIP or OCIP Insurance Details.');
                complete = false;
            }
            else setCertificateFileError('');
        }else setCertificateFileError('');

        setFilesRequired(filesShown);

        return complete;
    };

    useEffect(() => {
        setFilesComplete(checkFilesRequired());
    },[prevailingWage,bondRequired,taxStatus,prevailingWageFile,bondFile,taxExemptFile,certificateFileError,ccipInsurance]);

    
    // BUDGET - - - - - - - 
    const [budgetImport, setBudgetImport] = useState();
    const [formattedBudget, setFormattedBudget] = useState();
    const [formattedBudgetError, setformattedBudgetError] = useState();
    const [updating, setUpdating] = useState(false);
    const [newEstimateItem, setNewEstimateItem] = useState(false);
    const [taxesFound, setTaxesFound] = useState(false);
    const [perDiemFound, setPerDiemFound] = useState(false);
    const [bondFound, setBondFound] = useState(false);
    const [materialTotal, setMaterialTotal] = useState(0);
    const [laborTotal, setLaborTotal] = useState(0);
    const [equipmentTotal, setEquipmentTotal] = useState(0);
    const [subcontractorTotal, setSubcontractorTotal] = useState(0);
    const [otherTotal, setOtherTotal] = useState(0);
    const [totalBudget, setTotalBudget] = useState(0);
    const [taxError, setTaxError] = useState('');
    const [budgetError, setBudgetError] = useState('');
    const [budgetComplete, setBudgetCompete] = useState(false);
    const [skippingBudget, setSkippingBudget] = useState(false);
    const [budgetSkipReason, setBudgetSkipReason] = useState('');


    const checkBudget = () => {
        if(skippingBudget){
            console.log('Skip Budget')
            if(budgetSkipReason.length  > 5){
                console.log('Skipping Budget Success')
                setBudgetError('');
                return true;
            }
            else setBudgetError('Please enter a reason for skipping the budget.')
        }
        else{
            let complete = true;

            // TAX
            setTaxError('')
            if(taxStatus != 'Exempt'){
                if(formattedBudget) {
                    let taxesFound = false;
                    if(Object.keys(formattedBudget).includes(`${taxesCode}O`)) taxesFound=true;
                    if(Object.keys(formattedBudget).includes(`${salesTaxCode}O`)) taxesFound=true;
                    if(Object.keys(formattedBudget).includes(`${useTaxCode}O`)) taxesFound=true;

                    if(!taxesFound){
                        setTaxError('Project is not tax exempt and requires tax to be budgetted.');
                        complete = false;
                    }
                    else setTaxError('');
                }
            };

            // BUDGET
            if(!formattedBudget && !skippingBudget){
                setBudgetError('Upload a budget or provide a reason why it is not available.');
                complete = false;
            }
            else setBudgetError('');

            return complete;
        };
    };

    useEffect(() => {
        setBudgetCompete(checkBudget());
    },[formattedBudget,skippingBudget,budgetSkipReason]);

    const skipBudget = (value) => {
        setSkippingBudget(value);
    };

    // Refreshes Budget by setting updating to true temporary
    const refreshForms = () => {
        setUpdating(true);

        setTimeout(() => {
            setUpdating(false);
        },100);
    };

    // Updates the totals
    const updateTotals = () => {
        let tempMaterial = 0;
        let tempLabor = 0;
        let tempEquipment = 0;
        let tempSubcontractor = 0;
        let tempOther = 0;

        Object.keys(formattedBudget).map((identifier,key)=> {
            if(formattedBudget[identifier].group === 'M') tempMaterial += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'L') tempLabor += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'E') tempEquipment += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'S') tempSubcontractor += parseFloat((formattedBudget[identifier].total));
            else if(formattedBudget[identifier].group === 'O') tempOther += parseFloat((formattedBudget[identifier].total));
        });

        setMaterialTotal(numberWithCommas(parseFloat((tempMaterial).toFixed(2))));
        setLaborTotal(numberWithCommas(parseFloat((tempLabor).toFixed(2))));
        setEquipmentTotal(numberWithCommas(parseFloat((tempEquipment).toFixed(2))));
        setSubcontractorTotal(numberWithCommas(parseFloat((tempSubcontractor).toFixed(2))));
        setOtherTotal(numberWithCommas(parseFloat((tempOther).toFixed(2))));

        const tempTotal = tempMaterial+tempLabor+tempEquipment+tempSubcontractor+tempOther
        setTotalBudget(numberWithCommas(parseFloat((tempTotal).toFixed(2))));
    };

    // Do this when formatted Budget changes
    useEffect(() => {
        if(formattedBudget) updateTotals();
    },[formattedBudget]);

    // Clear the Budget
    const clearBudget = () => {
        setBudgetImport(null);
        setFormattedBudget(null);
    };

    // Do this when error on budget
    useEffect(() => {
        if(!formattedBudget && formattedBudgetError) clearBudget();
    },[formattedBudgetError]);

    // Formats budget when uploaded
    const importProjectBudget = () => {
        const extension = budgetImport[0].type
        const filename = `${projectName}-Original Budget-${getDate()}.xlsx`;
        sendProjectBudget(contextObject.apiToken, budgetImport, filename, setFormattedBudget, setformattedBudgetError);
    };

    // Do this when a budget import is selected
    useEffect(() => {
        if(!formattedBudget && budgetImport) importProjectBudget();
    },[budgetImport]);

    // Assigns an unassigned estimate item
    const assignBudgetLine = (index,costCode,description,group,title,total) => {
        let newFormattedBudget = formattedBudget;

        refreshForms();

        if(index >= 0){
            const newUnassignedArray = removeArrayItem(formattedBudget.unassigned,index);
            newFormattedBudget.unassigned = newUnassignedArray;
        }
        else{
            setNewEstimateItem(false);
        }

        const budgetKey = `${costCode}${group}`;

        let updated = false;
        for (const [key, value] of Object.entries(newFormattedBudget)) {
            if(key == budgetKey) {
                const newEstimateItem = {description:description, group:group, total:total};
                newFormattedBudget[budgetKey].estimateItemArray.push(newEstimateItem);
                const newTotal = parseFloat(newFormattedBudget[budgetKey].total) + parseFloat(total);
                newFormattedBudget[budgetKey].total = newTotal.toFixed(2);
                updated = true;
                break;
            };
        };

        if(!updated){
            const newEstimateItem = {description:description, group:group, total:total};
            newFormattedBudget[budgetKey] = {costCode:costCode, estimateItemArray:[newEstimateItem,], group:group, title:title, total:parseFloat(total)}
        };
        setFormattedBudget(newFormattedBudget);

        setTimeout(() => {
            updateTotals();
        },100);
    };

    // Updates an already assigned estimate item
    const updateBudgetLine = (index,originalCode,originalGroup,oldTotal,costCode,description,group,title,total) => {
        let newFormattedBudget = formattedBudget;

        refreshForms();

        // Remove from the existing Item Array
        const oldKey = `${originalCode}${originalGroup}`;
        const newEstimateItemArray = removeArrayItem(formattedBudget[oldKey].estimateItemArray,index);
        newFormattedBudget[oldKey].estimateItemArray = newEstimateItemArray;

        // Subtract from Total
        const newTotal = newFormattedBudget[oldKey].total - parseFloat(oldTotal);
        newFormattedBudget[oldKey].total = newTotal.toFixed(2);

        if(!newTotal > 0){
            delete newFormattedBudget[oldKey]
        }

        // Add to New Code 
        const newKey = `${costCode}${group}`;
        let updated = false;
        for (const [key, value] of Object.entries(newFormattedBudget)) {
            if(key == newKey) {
                const newEstimateItem = {description:description, group:group, total:total};
                newFormattedBudget[newKey].estimateItemArray.push(newEstimateItem);
                const newTotal = parseFloat(newFormattedBudget[newKey].total) + parseFloat(total);
                newFormattedBudget[newKey].total = newTotal.toFixed(2);
                updated = true;
                break;
            };
        };

        if(!updated){
            const newEstimateItem = {description:description, group:group, total:total};
            newFormattedBudget[newKey] = {costCode:costCode, estimateItemArray:[newEstimateItem,], group:group, title:title, total:total}
        };

        setFormattedBudget(newFormattedBudget);

        setTimeout(() => {
            updateTotals();
        },100);
    };

    // Deletes an estimate item
    const deleteEstimateItem = (costCode,group,index,total) => {
        if(index === -1){
            setNewEstimateItem(false);
        }
        else{
            let newFormattedBudget = formattedBudget;

            refreshForms();

            if(!costCode){
                const newUnassignedArray = removeArrayItem(formattedBudget.unassigned,index);
                newFormattedBudget.unassigned = newUnassignedArray;
            }
            else{
                // Remove from the existing Item Array
                const oldKey = `${costCode}${group}`;
                const newEstimateItemArray = removeArrayItem(formattedBudget[oldKey].estimateItemArray,index);
                newFormattedBudget[oldKey].estimateItemArray = newEstimateItemArray;

                // Subtract from Total
                const newTotal = newFormattedBudget[oldKey].total - parseFloat(total);
                newFormattedBudget[oldKey].total = newTotal.toFixed(2);

                if(!newTotal > 0){
                    delete newFormattedBudget[oldKey]
                };
            };

            setFormattedBudget(newFormattedBudget);

            setTimeout(() => {
                updateTotals();
            },100);
        };
    };


    // SUBMIT - - - - - - - 
    // Create a project booking object and send it.
    const createProjectBookingObject = () => {
        const projectBookingObject = {

        };

        sendProjectBookingRequest();
    };

    // Displays components to the screen
    return(
        <div className='Project-Booking'>
            <div className='project-Booking-Form'>
                { // CUSTOMER
                <>
                {   !customerSelected &&
                    <div className='Project-Booking-Section-Title'>Select a Customer</div>
                }
                {   customerSelected &&
                    <div className='Project-Booking-Section-Title'>Customer</div>
                }
                <CustomerDetailsForm {...customerDetailProps}/> 
                </>
                }

                {   customerSelected &&  locationSelected && // BRANCH & MANAGER
                <>
                <div className='Project-Booking-Section-Title'>Branch & Manager Details</div>
                <BranchManagementForm {...branchManagementProps}/>
                </>
                }
                
                {   customerSelected &&  locationSelected && branchAndManagerComplete &&  // PROJECT TYPE
                <>
                    <div className='Project-Booking-Section-Title'>Type</div>
                    <ProjectTypeForm projectType={projectType} setProjectType={setProjectType}/>
                </>
                }

                {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Project' &&  // PROJECT DETAILS
                <>
                <div className='Project-Booking-Section-Title'>Project Details</div>
                <ProjectDetailsForm {...projectDetailProps}/>

                    { projectNameError &&
                        <div className='Project-Booking-Error-Line'>{projectNameError}</div>
                    }
                    { projectAddressError &&
                        <div className='Project-Booking-Error-Line'>{projectAddressError}</div>
                    }
                </>
                }

                {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Service' && // SERVICE DETAILS
                <>
                <div className='Project-Booking-Section-Title'>Service Details</div>
                <ServiceDetailsForm {...serviceDetailProps}/>
                </>
                }

                {   customerSelected &&  locationSelected && branchAndManagerComplete && projectType === 'Project' && detailsComplete && // PROJECT CONTRACT
                <>
                <div className='Project-Booking-Section-Title'>Contract Details</div>
                <ProjectContractForm {...contractProps}/>
                { contractValueError &&
                    <div className='Project-Booking-Error-Line'>{contractValueError}</div>
                }
                { estiamtedCostError &&
                    <div className='Project-Booking-Error-Line'>{estiamtedCostError}</div>
                }
                { retainageError &&
                    <div className='Project-Booking-Error-Line'>{retainageError}</div>
                }
                </>
                }

                {   contractComplete && // LINKS
                    <> 
                    <div className='Project-Booking-Section-Title'>Links</div>
                    <StandardInputField title={'Sales Folder Link'} value={salesLink} setTo={setSalesLink} width={500}/>
                    { salesLinkError &&
                        <div className='Project-Booking-Error-Line'>{salesLinkError}</div>
                    }
                    </>
                }

                {   validSalesLink && filesRequired && // FILES
                    <> 
                    <div className='Project-Booking-Section-Title'>Files</div>
                    <FilesForm {...fileProps}/>
                    { prevailingWageFileError &&
                        <div className='Project-Booking-Error-Line'>{prevailingWageFileError}</div>
                    }
                    { bondFileError &&
                        <div className='Project-Booking-Error-Line'>{bondFileError}</div>
                    }
                    { certificateFileError &&
                        <div className='Project-Booking-Error-Line'>{certificateFileError}</div>
                    }
                    </>
                }
                
                {   validSalesLink && filesComplete && !skippingBudget && // BUDGET
                    <>
                    <div className='Project-Booking-Section-Title'>Budget</div>

                    <div className='Project-Booking-Budget'>
                        {   !budgetImport &&
                        <div className='Project-Booking-Budget-Import'>
                            <div className='Project-Booking-Budget-Import-Instructions'>
                                Please upload either a ProEst Export or Standard Budget. 
                            </div>
                            <div className='Project-Booking-Budget-Import-Instructions'>
                                If you do not have a budget for a valid reason, please click skip and enter the reason.
                            </div>
                            <div className='Project-Booking-Budget-Import-Controls'>
                                <button className='Project-Booking-Budget-Import-Template' onClick={ () => getBudgetTemplate() }>Download Template</button>
                                <input type='file' accept=".xls,.xlsx" onChange={(e) => setBudgetImport(e.target.files)}className='Project-Booking-Budget-Import-Upload'/>
                                <button className='Project-Booking-Budget-Import-Template' onClick={ () => skipBudget(true) }>Skip Budget</button>
                            </div>
                        </div>
                        }

                        {   budgetImport && !formattedBudget && !formattedBudgetError &&
                            <LoadingSpinner size={60}/>
                        }

                        {   formattedBudget && !formattedBudgetError && !updating &&
                        <>
                            <div  className='Project-Booking-Budget-Section-Header'>
                                <div style={{width:'100px', alignSelf: 'end'}}>Code</div>
                                <div style={{width:'350px', alignSelf: 'end'}}>Title</div>
                                <div style={{width:'120px', alignSelf: 'end'}}>Group</div>
                                <div style={{width:'100px', alignSelf: 'end'}}>Cost</div>
                            </div>
                            <ProjectBudgetForm formattedBudget={formattedBudget} setFormattedBudget={setFormattedBudget} assignBudgetLine={assignBudgetLine} updateBudgetLine={updateBudgetLine}
                            setTaxesFound={setTaxesFound} setPerDiemFound={setPerDiemFound} setBondFound={setBondFound} deleteEstimateItem={deleteEstimateItem}/>
                        </>

                        }

                        {   formattedBudgetError && 
                            <div>
                                Error formatting budget. Please verify format and resubmit.
                            </div>
                        }
                        {   newEstimateItem &&
                            <EstimateLineItem index={-1} description={''} group={''} total={0} assignBudgetLine={assignBudgetLine} deleteEstimateItem={deleteEstimateItem}/>
                        }
                        {   formattedBudget && !newEstimateItem &&
                            <button className='Project-Booking-Budget-New' onClick={() => setNewEstimateItem(true)}>Add New Cost to Budget</button>
                        }
                        {   formattedBudget &&
                            <button className='Project-Booking-Budget-Clear' onClick={() => clearBudget()}>Clear Budget</button>
                        }
                        
                        { formattedBudget &&
                            <TotalsBar materialTotal={materialTotal} laborTotal={laborTotal} equipmentTotal={equipmentTotal} subcontractorTotal={subcontractorTotal} otherTotal={otherTotal} totalBudget={totalBudget}/> 
                        }
                        { budgetError &&
                            <div className='Project-Booking-Error-Line'>{budgetError}</div>
                        }
                        {   taxError &&
                            <div className='Project-Booking-Error-Line'>{taxError}</div>
                        }
                    </div>
                    </>
                }

                {   skippingBudget && // SKIP BUDGET
                    <>
                    <div className='Project-Booking-Section-Title'>Reason for Skipping Budget</div>
                    <BudgetSkipForm budgetSkipReason={budgetSkipReason} setBudgetSkipReason={setBudgetSkipReason} skipBudget={skipBudget}/>
                    { budgetError &&
                        <div className='Project-Booking-Error-Line'>{budgetError}</div>
                    }
                    </>
                }
                
                {   customerSelected && locationSelected && branchAndManagerComplete && contractComplete && validSalesLink && filesComplete && budgetComplete && // SUBMIT BUTTON
                    <div className='Project-Booking-Submit'> 
                        <button className='Project-Booking-Submit-Button' onClick={() => createProjectBookingObject()}>Submit Project Booking Request</button>
                    </div>
                }
                    
            </div>
        </div>
    );
};

export default ProjectBooking;